import { motion } from "framer-motion"
import { graphql, Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import Heading from "../components/Heading"
import Layout from "../components/Layout"
import PageFooter from "../components/PageFooter"
import PageHeader from "../components/PageHeader"
import Img from "gatsby-image"

import {
  qrContainer,
  specification,
  specificationLabel,
  buttonInterest,
  floorplan,
  siteplan,
  masterplan,
  buttonLink,
} from "../styles/productDetail.module.scss"

function productDetails({ data }) {
  const banner = data.itemsJson.details.banner.childImageSharp.fluid
  const name = data.itemsJson.details.name
  const desc = data.itemsJson.details.desc
  const thumb = data.itemsJson.img.childImageSharp.fluid.src
  const gallery = data.itemsJson.details.gallery
  const spec = data.itemsJson.details.spesifikasi
  const floorplan = data.itemsJson.details.floorplan
  const siteplan = data.itemsJson.details.siteplan
  const masterplan = data.itemsJson.details.masterplan
  const brochure = data.itemsJson.details.brosur
  const barcode = data.itemsJson.details.barcode

  return (
    <Layout>
      <Helmet>
        <title>
          {name} | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan Terencana
        </title>
        <meta
          name="description"
          content="Trend kebutuhan akan rumah tinggal yang ideal telah berubah sejak pandemi, tidak cukup dengan lokasi yang strategis, fasilitas yang lengkap akan tetapi lingkungan yang nyaman, asri, bebas banjir dan aman menjadi faktor penting dalam mencari rumah tinggal ideal saat ini. Grand Wisata hadir dengan berbagai product rumah hunian yang inovative dan menggusung konsep rumah sehat yang memberikan rasa nyaman dan aman."
        />
        <meta
          name="keywords"
          content="grand wisata bekasi,grand wisata,sinar mas land,properti,real estate,rumah"
        />
        <meta name="distribution" content="web" />
        <meta
          property="og:title"
          content={`${name} | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan
          Terencana`}
        />
        <meta
          property="og:description"
          content="Trend kebutuhan akan rumah tinggal yang ideal telah berubah sejak pandemi, tidak cukup dengan lokasi yang strategis, fasilitas yang lengkap akan tetapi lingkungan yang nyaman, asri, bebas banjir dan aman menjadi faktor penting dalam mencari rumah tinggal ideal saat ini. Grand Wisata hadir dengan berbagai product rumah hunian yang inovative dan menggusung konsep rumah sehat yang memberikan rasa nyaman dan aman."
        />
        <meta property="og:image" content={thumb} />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <PageHeader bg={banner}></PageHeader>
        <section className="container">
          <Heading>{name}</Heading>
          <div className="row mb-5">
            <div className="col-md-8">
              {desc ? (
                <div dangerouslySetInnerHTML={{ __html: desc }}></div>
              ) : (
                <p>Deskripsi produk tidak tersedia</p>
              )}
            </div>
            <div className="col-md-4 mt-5 mt-sm-0 text-center">
              <a href="#tertarik" className={`button ${buttonInterest}`}>
                Saya Tertarik
              </a>
              {barcode.img ? (
                <div className={`w-75 ${qrContainer}`}>
                  <h4>Virtual Tour</h4>
                  <div className={`row justify-content-center`}>
                    {barcode.img.map((item, idx) => {
                      return (
                        <div className="col-4 col-sm-12" key={idx}>
                          <Img fluid={item.childImageSharp.fluid} />
                          <a
                            href={barcode.link[idx]}
                            target="_blank"
                            rel="noreferrer"
                            className="mb-5"
                          >
                            {barcode.label[idx]}{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </a>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <p>Virtual tour tidak tersedia</p>
              )}
            </div>
          </div>

          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-bs-ride="carousel"
            data-bs-touch="true"
          >
            <div class="carousel-indicators">
              {gallery.map((img, idx) => {
                return (
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={idx}
                    class={idx === 0 ? "active" : ""}
                    aria-current="true"
                    aria-label={`Slide ${idx}`}
                    key={idx}
                  ></button>
                )
              })}
            </div>
            <div class="carousel-inner">
              {gallery.map((img, idx) => {
                return (
                  <div
                    className={
                      idx === 0 ? "carousel-item active" : "carousel-item"
                    }
                    key={idx}
                  >
                    <Img
                      fluid={img.childImageSharp.fluid}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                )
              })}
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          {!spec ? (
            ""
          ) : (
            <div className="row mt-5">
              <h2 className="mb-5">Spesifikasi</h2>
              <div className="col-sm-6">
                {Object.entries(spec)
                  .filter(ent => ent[1] != null)
                  .sort()
                  .map((item, id) => {
                    if (id < Object.entries(spec).length / 2) {
                      if (!item[1]) return ""
                      return (
                        <div className={specification}>
                          <span className={specificationLabel}>
                            {(
                              item[0][0].toUpperCase() + item[0].substring(1)
                            ).replace(/([a-z0-9])([A-Z])/g, "$1 $2")}
                          </span>
                          <span>{item[1]}</span>
                        </div>
                      )
                    }
                  })}
              </div>
              <div className="col-sm-6">
                {Object.entries(spec)
                  .filter(ent => ent[1] != null)
                  .sort()
                  .map((item, id) => {
                    if (id > Object.entries(spec).length / 2) {
                      if (!item[1]) return ""
                      return (
                        <div className={specification}>
                          <span className={specificationLabel}>
                            {(
                              item[0][0].toUpperCase() + item[0].substring(1)
                            ).replace(/([a-z0-9])([A-Z])/g, "$1 $2")}
                          </span>
                          <span>{item[1]}</span>
                        </div>
                      )
                    }
                  })}
              </div>
              {/* <div className="col-sm-6">
                <div className={specification}>
                  <span className={specificationLabel}>Pondasi</span>
                  <span>{spec.pondasi}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Dinding</span>
                  <span>{spec.dinding}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Finishing</span>
                  <span>{spec.finishing}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Lantai Utama</span>
                  <span>{spec.lantaiUtama}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Atap</span>
                  <span>{spec.atap}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Kusen</span>
                  <span>{spec.kusen}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Daun Pintu Utama</span>
                  <span>{spec.daunPintuUtama}</span>
                </div>
              </div>
              <div className="col-sm-6">
                <div className={specification}>
                  <span className={specificationLabel}>
                    Daun Pintu K.Tidur, KM/WC
                  </span>
                  <span>{spec.daunPintuKTidur}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Plafond</span>
                  <span>{spec.plafond}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Sanitari</span>
                  <span>{spec.sanitari}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Air</span>
                  <span>{spec.air}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Listrik</span>
                  <span>{spec.listrik}</span>
                </div>
                <div className={specification}>
                  <span className={specificationLabel}>Carport</span>
                  <span>{spec.carport}</span>
                </div>
              </div> */}
            </div>
          )}
          {!floorplan ? (
            ""
          ) : (
            <div className={`row mt-5 ${floorplan}`}>
              <h2 className="mb-5">Tipe & Floor Plan</h2>

              {floorplan.map(floorplan => {
                return (
                  <div className="col-sm-6">
                    <Img fluid={floorplan.childImageSharp.fluid}></Img>
                  </div>
                )
              })}
            </div>
          )}
          {!siteplan ? (
            ""
          ) : (
            <div className={`row mt-5 ${siteplan}`}>
              <div className="col-12">
                <h2 className="mb-5">Site Plan</h2>
                {siteplan.length ? (
                  siteplan.map(plan => (
                    <Img fluid={plan.childImageSharp.fluid}></Img>
                  ))
                ) : (
                  <Img fluid={siteplan.childImageSharp.fluid}></Img>
                )}
              </div>
            </div>
          )}
          {!masterplan ? (
            ""
          ) : (
            <div className={`row mt-5 ${masterplan}`}>
              <div className="col-12">
                <h2 className="mb-5">Master Plan</h2>
                <Img fluid={masterplan.childImageSharp.fluid}></Img>
              </div>
            </div>
          )}

          <div className="row mt-5" id="tertarik">
            <div className="col-12">
              <h4 className="text-center">
                Untuk informasi, Promo, dan show unit silahkan hubungi langsung
                kontak dibawah ini.
              </h4>
              {/* {brochure && (
                <a
                  className={`button ${buttonLink} w-25 text-center`}
                  href={brochure}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download Brosur
                </a>
              )} */}
              <Link
                className={`button ${buttonLink} w-25 text-center`}
                to="/contact"
              >
                Hubungi kami
              </Link>
            </div>
          </div>
        </section>
        <PageFooter />
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  query productPage($slug: String) {
    itemsJson(link: { eq: $slug }) {
      img {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      details {
        desc
        floorplan {
          childImageSharp {
            fluid(maxWidth: 6000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        gallery {
          childImageSharp {
            fluid(maxWidth: 6000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        masterplan {
          childImageSharp {
            fluid(maxWidth: 6000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        name
        siteplan {
          childImageSharp {
            fluid(maxWidth: 6000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        spesifikasi {
          CCTV
          LantaiKamarMandi
          LantaiTeras
          air
          atap
          carport
          daunPintuKTidur
          daunPintuUtama
          dinding
          finishing
          kanopi
          kusen
          lantaiKamarMandi
          lantaiTeras
          lantaiUtama
          listrik
          pintuUtama
          plafond
          pondasi
          sanitari
          struktur
          smartHome
        }
        banner {
          childImageSharp {
            fluid(maxWidth: 6000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brosur
        barcode {
          img {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          label
          link
        }
      }
    }
  }
`

export default productDetails
